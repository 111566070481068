import { apiRequest, API_SUCCESS, API_ERROR } from "../../../actions/api";
import { ROOT_URL } from "../../../config/";
import Alert from "sweetalert2";
import {
  USER,
  USER_CREATE,
  USER_GET,
  setUserDetails,
  USER_EDIT,
  USER_BOOKINGS_GET,
  USER_BOOKINGS,
  USER_MEAL_TEMPLATE_SEND,
  setUserBookings,
  USER_MEAL_TEMPLATE,
  USER_BOOKINGS_PERIOD_LOGS,
  USER_BOOKINGS_PERIOD_LOGS_GET,
  setUserBookingsPeriodLogs,
  USER_BOOKINGS_WEBINAR_LOGS,
  USER_BOOKINGS_WEBINAR_LOGS_GET,
  setUserBookingsWebinarLogs,
  USER_BOOKINGS_SYMPTOM_LOGS,
  USER_BOOKINGS_SYMPTOM_LOGS_GET,
  setUserBookingsSymptomLogs,
  USER_BOOKINGS_CONSULT_LOGS,
  USER_BOOKINGS_CONSULT_LOGS_GET,
  setUserBookingsConsultLogs,
  USER_OFFERING_INCREASE,
  USER_OFFERING_DECREASE,
  USER_OFFERING,
  getUserDetails,
  USER_LAB_REPORT,
  USER_LAB_REPORT_GET,
  setUserLabReport,
  USER_EXPERT_REPORT_SEND,
  USER_EXPERT_REPORT,
  USER_LAB_REPORT_SEND,
} from "../../../actions/user";

export const userMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case USER_CREATE:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/user`,
            method: "post",
            feature: USER,
          })
        );
        break;
        
      case `${USER_GET}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/userDetails`,
            method: "get",
            feature: USER,
          })
        );
        break;

      case `${USER_BOOKINGS_GET}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/userBookings`,
            method: "get",
            feature: USER_BOOKINGS,
          })
        );
        break;

      case `${USER_EDIT}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/user`,
            method: "put",
            feature: USER,
          })
        );
        break;

      case `${USER_MEAL_TEMPLATE_SEND}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/sendMealTemplate`,
            method: "post",
            feature: USER_MEAL_TEMPLATE,
          })
        );
        break;
      case `${USER_MEAL_TEMPLATE} ${API_SUCCESS}`:
        Alert.fire({
          position: "top-end",
          icon: "success",
          title: "Sent",
          showConfirmButton: false,
          timer: 1500,
        });
        break;
      case `${USER_MEAL_TEMPLATE} ${API_ERROR}`:
        Alert.fire({
          position: "top-end",
          icon: "error",
          title: "Failed",
          showConfirmButton: false,
          timer: 1500,
        });
        break;

      case `${USER_EXPERT_REPORT_SEND}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/sendExpertReport`,
            method: "post",
            feature: USER_EXPERT_REPORT,
          })
        );
        break;
      case `${USER_EXPERT_REPORT} ${API_SUCCESS}`:
        Alert.fire({
          position: "top-end",
          icon: "success",
          title: "Sent",
          showConfirmButton: false,
          timer: 1500,
        });
        break;
      case `${USER_EXPERT_REPORT} ${API_ERROR}`:
        Alert.fire({
          position: "top-end",
          icon: "error",
          title: "Failed",
          showConfirmButton: false,
          timer: 1500,
        });
        break;

      case `${USER_OFFERING_INCREASE}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/addOffering`,
            method: "put",
            feature: USER_OFFERING,
          })
        );
        break;

      case `${USER_OFFERING_DECREASE}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/subtractOffering`,
            method: "put",
            feature: USER_OFFERING,
          })
        );
        break;

      case USER_BOOKINGS_PERIOD_LOGS_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/userBookingsPeriodLogs`,
            method: "get",
            feature: USER_BOOKINGS_PERIOD_LOGS,
          })
        );
        break;

      case USER_BOOKINGS_WEBINAR_LOGS_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/userBookingsWebinarLogs`,
            method: "get",
            feature: USER_BOOKINGS_WEBINAR_LOGS,
          })
        );
        break;

      case USER_BOOKINGS_SYMPTOM_LOGS_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/userBookingsSymptomLogs`,
            method: "get",
            feature: USER_BOOKINGS_SYMPTOM_LOGS,
          })
        );
        break;

      case USER_BOOKINGS_CONSULT_LOGS_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/userBookingsConsultLogs`,
            method: "get",
            feature: USER_BOOKINGS_CONSULT_LOGS,
          })
        );
        break;

      case USER_LAB_REPORT_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/v1/userLabTestReport`,
            method: "get",
            feature: USER_LAB_REPORT,
          })
        );
        break;

      case `${USER_LAB_REPORT_SEND}`:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/sendLabReport`,
            method: "post",
            feature: USER_EXPERT_REPORT,
          })
        );
        break;

      case `${USER_LAB_REPORT} ${API_SUCCESS}`:
        if (action.message === "Lab Report Sent") {
          Alert.fire({
            position: "top-end",
            icon: "success",
            title: "Sent",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          dispatch(setUserLabReport(action.payload));
        }
        break;

      case `${USER_LAB_REPORT} ${API_ERROR}`:
        if (action.message === "Could not send lab report") {
          Alert.fire({
            position: "top-end",
            icon: "error",
            title: "Failed",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        break;

      case `${USER} ${API_SUCCESS}`:
        dispatch(setUserDetails(action.payload));
        if (action?.message !== "User Details") {
            Alert.fire({
              position: "top-end",
              icon: "success",
              title: action?.message || "User created successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        break;

      case `${USER} ${API_ERROR}`:
        Alert.fire({
            position: "top-end",
            icon: "error",
            title: `<span style="font-size:24px;">${
              action?.payload?.response?.data?.responseStatus?.message ||
              "Failed to create user!"
            }</span>`,
            showConfirmButton: false,
            timer: 2500,
          });
        break;

      case `${USER_BOOKINGS} ${API_SUCCESS}`:
        dispatch(setUserBookings(action.payload));
        break;

      case `${USER_BOOKINGS} ${API_ERROR}`:
        dispatch(setUserBookings([]));
        break;
      case `${USER_OFFERING} ${API_SUCCESS}`:
        dispatch(getUserDetails(action.payload.userId));
        break;

      case `${USER_BOOKINGS_PERIOD_LOGS} ${API_SUCCESS}`:
        dispatch(setUserBookingsPeriodLogs(action.payload));
        break;

      case `${USER_BOOKINGS_PERIOD_LOGS} ${API_ERROR}`:
        dispatch(setUserBookingsPeriodLogs([]));
        break;

      case `${USER_BOOKINGS_WEBINAR_LOGS} ${API_SUCCESS}`:
        dispatch(setUserBookingsWebinarLogs(action.payload));
        break;

      case `${USER_BOOKINGS_WEBINAR_LOGS} ${API_ERROR}`:
        dispatch(setUserBookingsWebinarLogs([]));
        break;

      case `${USER_BOOKINGS_SYMPTOM_LOGS} ${API_SUCCESS}`:
        dispatch(setUserBookingsSymptomLogs(action.payload));
        break;

      case `${USER_BOOKINGS_SYMPTOM_LOGS} ${API_ERROR}`:
        dispatch(setUserBookingsSymptomLogs([]));
        break;

      case `${USER_BOOKINGS_CONSULT_LOGS} ${API_SUCCESS}`:
        dispatch(setUserBookingsConsultLogs(action.payload));
        break;

      case `${USER_BOOKINGS_CONSULT_LOGS} ${API_ERROR}`:
        dispatch(setUserBookingsConsultLogs([]));
        break;

      case `${USER_OFFERING} ${API_ERROR}`:
        Alert.fire({
          position: "top-end",
          icon: "error",
          title: action.message,
          showConfirmButton: false,
          timer: 1500,
        });
        break;
      default:
        break;
    }
  };
