const ApiEndpoints = {
  // v1 version
  skus: "/v1/skus",
  expertAvailability: "/v1/expertAvailability",
  dftCheckExpert : "/v1/checkExpert",
  dftConnectWithExpert: "/connectDoctorWithExpert",
  experts: "/experts",
};

export default ApiEndpoints;
