import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { getAllExpert } from "../../../actions/expert";
import { values } from "../../../constant/Values";
import { allExpertsSelector } from "../../../selector/expert";
import { ApiClient, ApiMethods } from "../../../middlewares/core/api/ApiClient";
import ApiEndpoints from "../../../middlewares/core/api/ApiEndpoints";
import { ROOT_URL } from "../../../config";
import { GET_EXPERTS_LIST } from "../../../middlewares/types";

const Expert = (props) => {
    const { connectWithExpert, expert } = props
    const dispatch = useDispatch()
    const [expertList, setExpertList] = useState([{name: 'Select expert', userId: '', isDisabled: true}, {name: 'None', userId: 'None'}])
    const [expertPageNo, setExpertPageNo] = useState(0)
    const [hasMoreFlag, setHasMoreFlag] = useState(true)
    const [searchField, setSearchField] = useState("")

    const onMenuScrollToBottom = () => {
        hasMoreFlag && setExpertPageNo(expertPageNo + 1)
    }

    useEffect(() => {
        hasMoreFlag && dispatch(getAllExpert({ pageSize: values.expertPageSize, page: expertPageNo }));
    }, [dispatch, expertPageNo]);

    const { expertData } = useSelector((state) => allExpertsSelector(state));

    useEffect(() => {
        if (expertData?.length == 0) {
            setHasMoreFlag(false)
        } else {
            setExpertList([...expertList, ...(expertData ?? [])])
        }
    }, [expertData])

    const handleSearch = async (searchValue) => {
        try {
            const res = await ApiClient({
              method: ApiMethods.POST,
              endpoint: ApiEndpoints.experts,
              data: { pageNo: 1, pageSize: 30, status: 1, searchValue },
              feature: GET_EXPERTS_LIST,
            });
      
            if (res?.responseStatus?.statusCode) {
              return res?.responseData?.expertData;
            }
        } catch (error) {
            return [];
        }
    }
    
    return (
        <AsyncSelect
            isDisabled={false}
            defaultOptions={expertList}
            onChange={connectWithExpert}
            value={expertList.find(option => expert === `${option.name} - ${option.userId}` || expert == option.userId)}
            loadOptions={handleSearch}
            inputValue={searchField}
            onInputChange={setSearchField}
            placeholder={"Select Expert"}
            noOptionsMessage={() => "No Expert Found"}
            getOptionLabel={(option) =>
                option.userId === '' || option.userId === 'None' ? option.name : `${option.name} - ${option.userId}`
            }
            getOptionValue={(option) => option.userId === '' ? option.userId : `${option.name} - ${option.userId}`}
            onMenuScrollToBottom={onMenuScrollToBottom}
        />
    )
}

export default Expert